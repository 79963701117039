<template >
	<div class="container">
		<router-link :to="i.path" class="tab" :class="{ active: r === i.path }" v-for="i in navs" :key="i.name">{{ i.name }}</router-link>
	</div>
</template>
<script>
export default {
	data() {
		return {
			navs: [
				{ name: '总览', path: 'overview' },
				{ name: '慢病防治', path: 'treatment' },
				{ name: '家庭医生', path: 'doctor' },
				// { name: '医疗服务', path: 'medic' },
				// { name: '公共卫生', path: 'public' },
				// { name: '中医药建设', path: 'tcm' },
        { name: '巡诊驻派', path: 'visitAndDispatch' },
				{ name: '绩效考核', path: 'kpi' },
				// { name: '培训服务', path: 'train' },
				{ name: '合作企业', path: 'teamwork' },
			],
		};
	},
	computed: {
		r() {
			const paths = this.$route.path.split('/');
			return paths[paths.length - 1];
		},
	},
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	margin-left: 60px;
	.tab {
		font-style: italic;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 120px;
		height: 81px;
		color: #aecaf5;
		text-decoration: none;
		font-size: 16px;
		font-weight: 600;
		background-position: center;
		background-position-y: 4px;
	}
	.tab.active {
		background-image: url('./assets/title-active.png');
		background-size: 100% 100%;
		color: white;
	}
}
</style>